import React from 'react';
import { HTMLTable, Button } from "@blueprintjs/core";
import { mockAccounts } from '../mockData';
import './AccountMetricsTable.css';

const AccountMetricsTable = () => {
  return (
    <div className="account-metrics-table">
      <div className="table-header">
        <h2>Account Metrics</h2>
        <Button minimal={true} icon="arrow-right" text="View All" />
      </div>
      <HTMLTable interactive={true} striped={true} className="custom-table">
        <thead>
          <tr>
            {['Account Name', 'ARR', 'Success & Services', 'Advisory', 'Next Renewal', 'MAU', 'API Calls (30d)', 'Last Call'].map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {mockAccounts.map((account) => (
            <tr key={account.name}>
              <td data-label="Account Name">{account.name}</td>
              <td data-label="ARR">${account.arr.toLocaleString()}</td>
              <td data-label="Success & Services">${account.successServicesRevenue.toLocaleString()}</td>
              <td data-label="Advisory">${account.advisoryRevenue.toLocaleString()}</td>
              <td data-label="Next Renewal">{account.nextRenewalDate}</td>
              <td data-label="MAU">{account.monthlyActiveUsers.toLocaleString()}</td>
              <td data-label="API Calls (30d)">{account.apiCalls30Days.toLocaleString()}</td>
              <td data-label="Last Call">{account.lastCallDate}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </div>
  );
};

export default AccountMetricsTable;