import React from 'react';
import { Card, Elevation } from "@blueprintjs/core";
import AccountMetricsTable from './components/AccountMetricsTable';
import './App.css';

function App() {
  return (
    <div className="App" style={{ backgroundColor: "#F5F5F5" }}>
      <h1>Account Leads HQ</h1>
      <div className="grid-container">
        <Card elevation={Elevation.TWO} className="dashboard-card">
          <h2>Monthly Revenue</h2>
          {/* Add a chart or metrics here */}
        </Card>
        <Card elevation={Elevation.TWO} className="dashboard-card">
          <h2>Active Users</h2>
          {/* Add a chart or metrics here */}
        </Card>
        <Card elevation={Elevation.TWO} className="dashboard-card full-width">
          <AccountMetricsTable />
        </Card>
      </div>
    </div>
  );
}

export default App;