export const mockAccounts = [
    {
      name: 'Amazon',
      arr: 1000000,
      successServicesRevenue: 150000,
      advisoryRevenue: 50000,
      nextRenewalDate: '2024-12-31',
      monthlyActiveUsers: 5000,
      apiCalls30Days: 1000000,
      lastCallDate: '2023-06-15',
    },
    {
      name: 'LinkedIn',
      arr: 750000,
      successServicesRevenue: 100000,
      advisoryRevenue: 25000,
      nextRenewalDate: '2024-09-30',
      monthlyActiveUsers: 3000,
      apiCalls30Days: 750000,
      lastCallDate: '2023-06-10',
    },
    {
      name: 'JPMorgan',
      arr: 1250000,
      successServicesRevenue: 200000,
      advisoryRevenue: 75000,
      nextRenewalDate: '2025-03-31',
      monthlyActiveUsers: 7500,
      apiCalls30Days: 1500000,
      lastCallDate: '2023-06-18',
    },
    {
      name: 'Fidelity',
      arr: 900000,
      successServicesRevenue: 120000,
      advisoryRevenue: 40000,
      nextRenewalDate: '2024-11-30',
      monthlyActiveUsers: 4000,
      apiCalls30Days: 900000,
      lastCallDate: '2023-06-12',
    },
    {
      name: 'Deloitte',
      arr: 1100000,
      successServicesRevenue: 180000,
      advisoryRevenue: 60000,
      nextRenewalDate: '2025-01-31',
      monthlyActiveUsers: 6000,
      apiCalls30Days: 1200000,
      lastCallDate: '2023-06-20',
    },
  ];